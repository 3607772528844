<template>
    <div class>
        <el-row class="works-title">
            <el-col :span="12" class="left">{{tActivity.name}}</el-col>
            <el-col :span="12" class="right">评分时间：{{tActivity.reviewStartTime}} ~ {{tActivity.reviewEndTime}}</el-col>
        </el-row>

        <z-plate has-bottom-border style="position: relative">
            <!--	 切换icon-->
            <div class="toggle-icon" @click="saveUserSettingInfo">
                <span v-show="viewType == 0"></span>
                <span v-show="viewType == 1"></span>
            </div>
            <template #titleLeft>
                <el-tabs v-model="activeName" @tab-click="selTabView" v-if="Object.keys(progessDTO).length">
                    <el-tab-pane :label="'全部（'+ progessDTO.totalCount +'）'" name="first"></el-tab-pane>
                    <el-tab-pane :label="'已评（'+ progessDTO.lockCount +'）'" name="second"></el-tab-pane>
                    <el-tab-pane :label="'未评（'+ progessDTO.unlockCount +'）'" name="third"></el-tab-pane>
                </el-tabs>
            </template>
            <!--新增-->
            <template>
                <div class="sort-items">
                    <span v-for="item in groupList" :id="item.channelId" style="cursor: pointer;" :class="item.channelId == channelId ? 'isActive' : ''" @click="queryViewTable(item)">{{item.groupName}}({{item.videoCount}})</span>
                </div>
            </template>
            <template>
                <div style="margin-top: 20px;">
                    <div  v-if="viewType == 0">
                        <el-row class="works-list" :gutter="45" v-loading="listLoading">
                            <el-col :span="6" class="works-item" v-for="(item, index) in page.content" :key="index"
                                    @click.native="turnToDetail(item)">
                                <div class="img-box">
                                    <el-image class="img" :src="item.mpgImg" fit="fill">
                                        <div slot="error" class="image-slot">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </el-image>
                                    <span class="state-style is-state__success" v-if="item.status">已评</span>
                                    <span class="state-style is-state__error" v-else>未评</span>
                                    <!--<span class="timebar">21:43</span>-->
                                </div>

                                <div class="title" :title="item.videoName" v-if="randomFlag==0" >
                                    <div class="text-space" style="width: 84%; display: inline-block; vertical-align: middle; "
                                         v-if="item.totalScore>0">{{item.videoName}}
                                    </div>
                                    <label style="width: 14%; text-align: right; display: inline-block; " v-if="item.totalScore>0">{{item.totalScore}}分</label>
                                    <div class="text-space" style="width: 100%; display: inline-block; vertical-align: middle; " v-else>
                                        {{item.videoName}}
                                    </div>
                                </div>
                                <div class="title" :title="item.videoName" v-else >
                                    <div class="text-space" style="width: 84%; display: inline-block; vertical-align: middle; "
                                         v-if="item.totalScore>0">{{item.randomCode}}
                                    </div>
                                    <label style="width: 14%; text-align: right; display: inline-block; " v-if="item.totalScore>0">{{item.totalScore}}分</label>
                                    <div class="text-space" style="width: 100%; display: inline-block; vertical-align: middle; " v-else>
                                        {{item.randomCode}}
                                    </div>
                                </div>
                                <p class="info" v-if="randomFlag==0">{{item.schoolName}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{item.teacherName}}</p>
                            </el-col>
                        </el-row>
                    </div>
                    <div v-else>
                        <!-- 表格 -->
                        <el-table class="z-table" :data="page.content" style="width: 100%;margin-top: 30px">
                            <el-table-column type="index" label="序号" width="60"></el-table-column>
                            <el-table-column prop="groupChannel" label="组别"/>
                            <el-table-column label="作品名称" v-if="randomFlag==0">
                                <template slot-scope="scope">
                                    <span @click="turnToDetail(scope.row)" class="works-name">{{ scope.row.videoName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="schoolName" label="上报单位" v-if="randomFlag==0"/>
                            <el-table-column width="120" prop="teacherName" label="上报人" v-if="randomFlag==0"/>
                            <el-table-column label="视频编码" v-if="randomFlag==1">
                                <template slot-scope="scope">
                                    <span @click="turnToDetail(scope.row)" class="works-name">{{ scope.row.randomCode }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column width="120" label="成绩">
                                <template slot-scope="scope">
                                    <span style="color: #00a6f4"
                                          v-if="scope.row.status">{{ scope.row.totalScore }}</span>
                                    <span style="color: red" v-else>未评分</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 分页 -->
                    <z-pagination v-if="pagination.totalPages > 0" :page.sync="pagination.page" :limit.sync="pagination.size" :total="pagination.totalPages" :pageSizes="pagination.pageSizes" @pagination="init"></z-pagination>
                </div>
            </template>
        </z-plate>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                type: '',
                viewType: 0,//0：默认图标 1：表格
                channelId: '',//二级分类id
                groupList:[],//二级菜单数据
                activeName: "first",
                pagination: {
                    page: 0, //当前页
                    size: 12, //分页条数
                    totalPages: 0, //总页数
                    pageSizes: [12, 24, 36, 48]
                },
                page: {
                    content: []
                },
                tActivity: {},
                progessDTO: {},
                listLoading: false,
                status: '',
                randomFlag: 1,
            };
        },

        mounted() {
            this.queryHomeGroupList();
            //显示方式查询
            this.queryUserSettingInfo();
            this.init();
        },

        methods: {
            queryViewTable(obj){
                this.channelId = obj.channelId;
                this.pagination.page = 0;
                this.init();
            },
            //保存设置
            saveUserSettingInfo(){
                var viewFlag = 0;
                if (this.viewType == 0){
                    viewFlag = 1;
                }
                this.$post('/saveUserSettingInfo', {"viewType":viewFlag}).then(rep => {
                    this.queryUserSettingInfo();
                })
            },
            queryUserSettingInfo(){
                this.$post('/queryUserSettingInfo', {
                }).then(rep => {
                    this.viewType = rep.content.viewType;
                })
            },
            queryHomeGroupList(){
                this.$post('/queryHomeGroupList', {"type": this.status}).then(rep => {
                    this.groupList = rep.content.groupList;
                })
            },
            selTabView(){
                if (this.activeName == 'first') {
                    this.status = '';
                } else if (this.activeName == 'second') {
                    this.status = '1';
                } else if (this.activeName == 'third') {
                    this.status = '0';
                }
                this.queryHomeGroupList();
                this.pagination.page = 0;
                this.init();
            },
            init() {
                if (sessionStorage.getItem("channelId") != null){
                    this.channelId = sessionStorage.getItem("channelId");
                    sessionStorage.removeItem("channelId");
                }

                if (sessionStorage.getItem("status") != null){
                    this.status = sessionStorage.getItem("status");
                    sessionStorage.removeItem("status");
                }

                if (sessionStorage.getItem("page") != null){
                    this.pagination.page = sessionStorage.getItem("page");
                    sessionStorage.removeItem("page");
                }

                if (sessionStorage.getItem("size") != null){
                    this.pagination.size = sessionStorage.getItem("size");
                    sessionStorage.removeItem("size");
                }

                if (sessionStorage.getItem("activeName") != null){
                    this.activeName = sessionStorage.getItem("activeName");
                    sessionStorage.removeItem("activeName");
                }

                let {pagination} = this;
                this.tableLoading = true;
                this.$post('/queryJudgeVideoList', {
                    channelId: this.channelId,
                    status: this.status,
                    page: pagination.page,
                    size: pagination.size
                }).then(rep => {
                    this.page = rep.content.page;
                    this.progessDTO = rep.content.progessDTO;
                    this.tActivity = rep.content.tActivity;
                    this.randomFlag = this.tActivity.randomFlag;
                    pagination.page = this.page.number;
                    pagination.totalPages = this.page.totalElements;
                    this.tableLoading = false;
                })
            },
            turnToDetail(item) {
                sessionStorage.setItem("channelId", this.channelId);
                sessionStorage.setItem("status", this.status);
                sessionStorage.setItem("page", this.pagination.page);
                sessionStorage.setItem("size", this.pagination.size);
                sessionStorage.setItem("activeName", this.activeName);

                sessionStorage.setItem("video", JSON.stringify(item));
                sessionStorage.setItem("randomFlag", this.randomFlag);
                this.$router.push({
                    name: 'WorksDetail'
                });

            },
        }
    };
</script>

<style lang="scss" scoped>
    .isActive{
        color: #00a6f4;
    }
    .works-name {
        cursor: pointer;
        color: #00a6f4;
    }

    .sort-items {
        padding: 20px 20px 0;
        margin-bottom: -10px;

        span {
            margin-right: 20px;
        }
    }

    .toggle-icon {
        position: absolute;
        top: 22px;
        right: 20px;
        display: inline-block;
        & > span:first-child {
            background: url("../../assets/img/grid-icon.png") no-repeat center center;
        }
        & > span:last-child {
            background: url("../../assets/img/list-icon.png") no-repeat center center;
        }
        span {
            display: inline-block;
            width: 32px;
            height: 28px;
            border: 1px solid #ccc;
            border-radius: 2px;
            cursor: pointer;

        }
    }

    .works-title {
        margin-bottom: 24px;
        font-size: 18px;
        color: #354052;

        .right {
            color: $--color-primary;
            text-align: right;
        }
    }

    .works-list {
        padding: 10px 30px 0;

        .works-item {
            margin-bottom: 40px;
            cursor: pointer;
        }

        .img-box {
            position: relative;
            margin-bottom: 20px;

            &::before {
                content: "";
                display: block;
                width: 0;
                padding-top: 61.38211%;
            }

            .img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }

            .state-style {
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 12px;
            }

            .timebar {
                position: absolute;
                bottom: 10px;
                right: 10px;
                padding: 5px;
                background-color: rgba(0, 0, 0, 0.5);
                color: #fff;
                font-size: 12px;
                border-radius: 5px;
            }
        }

        .title {
            margin-bottom: 10px;
            color: #666;
            /*white-space: nowrap; //文本强制不换行；
    text-overflow:ellipsis; //文本溢出显示省略号；
    overflow:hidden; //溢出的部分隐藏；*/
        }

        .info {
            color: #777;
            font-size: 12px;
        }
    }

    /deep/ .image-slot {
        background-color: #eff3f6;
        width: 100%;
        height: 100%;
        text-align: center;

        i {
            position: relative;
            top: 45%;
        }
    }
</style>